import {
  HOME_UPDATE,
  ALL_HOME_PAGE_CONTENT_REQUEST,
  STORE_UPDATE,
  GET_STORE_ALL,
  GET_DELIVERY_ADDRESSES,
  GET_DELIVERY_ADDRESS,
  SUBMIT_DELIVERY_ADDRESS,
  USER_SEARCH_ADDRESS,
  GET_TOKENIZED_CARDS,
  REMOVE_TOKENIZED_CARDS,
  CREATE_TOKENIZED_CARD,
  ORDER_STATUS_POLLING,
  UPDATE_ORDER_STATUS_POLLING,
  SINGLE_STORE_DETAIL,
  GET_STORE_GROUP_NAME,
  REMOVE_ADDRESS,
  GET_CLIENT_DATA,
  GET_FAQ_DATA,
  SET_USER_ADDR_ID,
} from "./types";

const homeUpdate = (payload: any) => ({
  type: HOME_UPDATE,
  payload,
});

const allHomeScreenContenRequest = (payload: any) => ({
  type: ALL_HOME_PAGE_CONTENT_REQUEST,
  payload,
});


const setUserAddrId = (payload: any) => ({
  type: SET_USER_ADDR_ID,
  payload,
});


const storeUpdate = (payload: any) => ({
  type: STORE_UPDATE,
  payload,
});

const getStoreAll = (payload: any) => ({
  type: GET_STORE_ALL,
  payload,
});

const getAllDeliveryAddresses = (payload: any) => ({
  type: GET_DELIVERY_ADDRESSES,
  payload,
});

const getDeliveryAddress = (payload: any) => ({
  type: GET_DELIVERY_ADDRESS,
  payload,
});

const createUpdateDeliveryAddress = (payload: any) => ({
  type: SUBMIT_DELIVERY_ADDRESS,
  payload,
});

const userSearchAddress = (payload: any) => ({
  type: USER_SEARCH_ADDRESS,
  payload,
});

const getTokenizedCardAction = (payload: any) => ({
  type: GET_TOKENIZED_CARDS,
  payload,
});
const removeTokenizedCardAction = (payload: any) => ({
  type: REMOVE_TOKENIZED_CARDS,
  payload,
});

const createTokenizedCardAction = (payload: any) => ({
  type: CREATE_TOKENIZED_CARD,
  payload,
});

const getOrderStatusAction = (payload: any) => ({
  type: ORDER_STATUS_POLLING,
  payload,
});
const UpdateOrderStatusPolling = (payload: any) => ({
  type: UPDATE_ORDER_STATUS_POLLING,
  payload,
});

const getSingleStoreDetailAction = (payload: any) => ({
  type: SINGLE_STORE_DETAIL,
  payload,
});
const removeAddress = (payload: any) => ({
  type: REMOVE_ADDRESS,
  payload,
});

const getstoreGroupsName = (payload: any) => ({
  type: GET_STORE_GROUP_NAME,
  payload,
});

const getClientData = (payload: any) => ({
  type: GET_CLIENT_DATA,
  payload,
});
const getFAQ = (payload: any) => ({
  type: GET_FAQ_DATA,
  payload,
});
export {
  getFAQ,
  homeUpdate,
  storeUpdate,
  setUserAddrId,
  allHomeScreenContenRequest,
  getAllDeliveryAddresses,
  getDeliveryAddress,
  createUpdateDeliveryAddress,
  userSearchAddress,
  getTokenizedCardAction,
  createTokenizedCardAction,
  getOrderStatusAction,
  getSingleStoreDetailAction,
  removeAddress,
  removeTokenizedCardAction,
  getstoreGroupsName,
  getStoreAll,
  getClientData,
  UpdateOrderStatusPolling,
};
