import Axios, { AxiosResponse } from "axios";
import config from "../config/app";
import * as interceptor from "./interceptor";
import { getPreAuthedToken } from "./auth/helper";
import axios from "axios";


const { ECommerce, customerSecretKey } = config;


const ecClient = Axios.create({
  baseURL: ECommerce.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});
const ecOrderClient = Axios.create({
  baseURL: `${ECommerce.BASE_URL}/order`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});
const ecStoreClient = Axios.create({
  baseURL: `${ECommerce.BASE_URL}/stores`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});


const ecUserClient = Axios.create({
  baseURL: `${ECommerce.BASE_URL}/customer`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});

// customer-api
ecUserClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecUserClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);



// ecommerce-api
ecClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);

// order-api
ecOrderClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecOrderClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);

// store-api
ecStoreClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecStoreClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);


export const authForgetPassword = (params: any = {}) =>
  ecUserClient({
    method: "post",
    url: "auth/forgotPassword",
    data: {
      email: params.email,
    },
  });

export const resetPassword = (params: any) => {
  return ecUserClient({
    method: "post",
    url: "auth/resetPassword",
    data: params,
  });
};

export const resetPasswordEmail = (params: any) => {
  return ecUserClient({
    method: "post",
    url: "auth/resetPassword/email",
    data: params,
  });
};

export const login = (params: any = {}) =>
  ecUserClient({
    method: "post",
    url: "auth/login",
    data: {
      email: params.email,
      password: params.password,
    },
  });

export const signup = (params: any) => {


  return ecUserClient({
    method: "post",
    url: "auth/signup",
    data: { ...params },
    headers: {
      "x-pre-auth-token": getPreAuthedToken()
    }
  });
}


export const otpVerifiedd = (params: any) =>
  ecUserClient({
    method: "post",
    url: "auth/OTPVerification",
    data: { ...params },
  });
export const resetPasswordOtpVerificationn = (params: any) =>
  ecUserClient({
    method: "post",
    url: "auth/resetPasswordOTPVerification",
    data: { ...params },
  });

export const resetPasswordQuery = (params: any) =>
  ecUserClient({
    method: "post",
    url: "passwordResetQuery",
    data: { ...params },
  });
export const loginQueryApi = (params: any) =>
  ecUserClient({
    method: "post",
    url: "accountLoginQuery",
    data: { ...params },
  });

export const sendOTP = (params: any) =>
  ecUserClient({
    method: "post",
    url: "auth/sendOTP",
    data: { ...params },
  });

export const forgotPasswordOTP = (params: any) =>
  ecUserClient({
    method: "post",
    url: "auth/sendOTPForgetPassword",
    data: { ...params },
  });

export const updateNumber = (params: any) =>
  ecUserClient({
    method: "post",
    url: "auth/updatePhoneNumber",
    data: { ...params },
  });

export const currentUserDetail = () =>
  ecUserClient({
    method: "get",
    url: `users/currentUserDetail`,
  });

  export const currentUserChangePassword = (oldPassword: string, newPassword: string) =>
    ecUserClient({
      method: "post",
      url: `auth/changePassword`,
      data: {
        oldPassword,
        newPassword
      }
    });


export const updateUserDetail = (params: any) =>
  ecUserClient({
    method: "post",
    url: `users/updateUserDetail`,
    data: { ...params },
  });

export const getBannerImage = () =>
  ecClient({
    method: "get",
    url: `bannerImages`,
  });

export const getAllDeliveryAddress = () =>
  ecUserClient({
    method: "get",
    url: `deliveriesAddresses`,
  });
export const removeAddressApi = (params: any) =>
  ecUserClient({
    method: "get",
    url: `deliveriesAddresses/remove/${params.userUuid}`,
  });
export const getDeliveryAddressApi = (params: any) =>
  ecUserClient({
    method: "get",
    url: `deliveriesAddresses/${params.id}`,
  });

export const ClientData = () =>
  ecUserClient({
    method: "get",
    url: `client`,
  });
export const createUpdateDeliveryAddressApi = (params: any) =>
  ecUserClient({
    method: "post",
    url: "deliveriesAddresses",
    data: { ...params },
  });

export const createOrderApi = (params: any) =>
  ecOrderClient({
    method: "post",
    url: `createOrder`,
    data: { ...params },
  });

export const getStoreBoundries = (params: any) =>
  ecUserClient({
    method: "get",
    url: `store/boundries/${params.id}`,
  });

export const getStores = () =>
  ecStoreClient({
    method: "get",
    url: `stores/ecommerce`,
  });

export const getStoresWithQuery = (query: any) =>
  ecStoreClient({
    method: "post",
    url: `stores/ecommerce`,
    data: query,
  });


export const UserOrderHistory = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `userHistoryWithDeployInfo`,
  });

export const ListUserOrderHistory = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `userHistory`,
  });

export const addToFavoritesApi = (params: any = {}) =>
  ecOrderClient({
    method: "post",
    url: "favorites/add",
    data: params,
  });
export const getUserFavoritesApi = () =>
  ecOrderClient({
    method: "get",
    url: `favorites/getFav`,
  });

export const getFavWithMenuDetails = () =>
  ecOrderClient({
    method: "get",
    url: `favorites/getFavWithMenuDetails`,
  });

export const removeFromFavoritesApi = (params: any) =>
  ecOrderClient({
    method: "post",
    url: `favorites/removeItem`,
    data: params
  });

export const removeFavoritesByItemid = (params: any) =>
  ecOrderClient({
    method: "post",
    url: `favorites/removeFavoritesByItemid`,
    data: params
  });


// 


export const getTokenizedCards = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `getTokenizeData`,
  });

export const tokenizeCards = (params: any) =>
  ecOrderClient({
    method: "post",
    url: `tokenizeCard`,
    data: { ...params },
  });

  export const makdeTokenizedCardDefault = (cardId: string) =>
    ecOrderClient({
      method: "post",
      url: `tokenizeData/changeDefault/${cardId}`,
    });
  


export const removeTokenizedCards = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `tokenizeData/remove/${params.uuid}`,
  });
export const getSingleStoreDetailApi = (params: any) =>
  ecStoreClient({
    method: "get",
    url: `stores/${params.id}`,
  });

export const getstoreGroupsNames = () =>
  ecUserClient({
    method: "get",
    url: `store/storeGroupsName`,
  });
export const getJsonApi = () =>
  ecOrderClient({
    method: "get",
    url: `/customerChannelMenuMapping/getjson`,
  });

export const allMenus = (params: any) =>
  ecOrderClient({
    method: "post",
    url: "/menu",
    data: {
      storeUuid: params.storeUuid,
    },
  });
export const menu = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `/menu/${params.menuGroupId}/${params.menuId}`,
  });

export const apiCall = () =>
  ecClient({
    method: "get",
    url: `client`,
  });
export const getFaqData = () =>
  ecClient({
    method: "get",
    url: `faq`,
  });

export const orderDetailsApi = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `/orders/ecommerceOrderDetails/${params.orderUuid}`,
  });


// static 

const isrClient = Axios.create({
  baseURL: ECommerce.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
});


export const getClientContacts = () =>
  isrClient({
    url: "clientContact/active",
    method: "get",
    headers: {
      customer_secret: customerSecretKey,
    }
  });

export const getClientDocuments = () =>
  isrClient({
    method: "get",
    url: `clientDocuments/active`,
    headers: {
      customer_secret: customerSecretKey,
    }
  });

export const getClientDocumentById = (id: string) =>
  isrClient({
    method: "get",
    url: `clientDocuments/specific/${id}`,
    headers: {
      customer_secret: customerSecretKey,
    }
  });


export const getFaqData2 = () =>
  isrClient({
    method: "get",
    url: `faqs/active`,
    headers: {
      customer_secret: customerSecretKey,
    }
  });

export const getClientHomeBanners = () =>
  isrClient({
    method: "get",
    url: `clientImages/home`,
    headers: {
      customer_secret: customerSecretKey,
    }
  });


// new menu api 


export const getDeploymentInfo = (storeUuid: string, channelCode?: string) =>
  ecStoreClient({
    method: "get",
    url: `menu/deploymentInfo/${storeUuid}?channelCode=${channelCode}`,
  });



export const getActiveMenu = (storeUuid: string, channelCode?: string) =>
  ecStoreClient({
    method: "get",
    url: `menu/active/${storeUuid}?channelCode=${channelCode}`,
  });

export const getMenuItem = (deployment: string, itemId: string) => {
  return axios.get(`${ECommerce.BASE_URL}/stores/menu/menuItem/${deployment}/${itemId}/image.png`, {
    headers: {
    //  "Content-Type": "application/json",
      customer_secret: customerSecretKey,
    }
  })
}

export const getMenuVariantByParentId = (deployment: string, itemId: string, parentId: string) => {
  return axios.get(`${ECommerce.BASE_URL}/stores/menu/variantByParentId/${deployment}/${itemId}/${parentId}/image.png`, {
    headers: {
    //  "Content-Type": "application/json",
      customer_secret: customerSecretKey,
    }
  })
}


// order api

export const listOrderByUser = () => ecOrderClient({
  method: "get",
  url: "/history"
})

export const createOrder = (data: any) => ecOrderClient({
  method: "post",
  url: "/new/ecommerce",
  data
})

export const getOrderById = (orderId: string) => ecOrderClient({
  method: "get",
  url: `/${orderId}`,
})

export const getUserOrderTrackInfo = (orderId: string) => ecOrderClient({
  method: "get",
  url: `/trackInfo/${orderId}`,
})


export const getUserOrderLiveStatus = (orderId: string): Promise<AxiosResponse<{orderStatus: string}>> => ecOrderClient({
  method: "get",
  url: `/liveStatus/${orderId}`,
})

