import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/reducer";
import homeReducer from "./home/reducer";

const config = {
  key: "root",
  storage,
  whitelist: ["auth", "home"],
};

export const rootReducer = persistCombineReducers(config, {
  auth: authReducer,
  home: homeReducer,
});
