import { call } from "redux-saga/effects";
import {
  getstoreGroupsNames,
  tokenizeCards,
  ClientData,
  getFaqData,
  getAllDeliveryAddress,
  getStores,
  getDeliveryAddressApi,
  getTokenizedCards,
  removeTokenizedCards,
  getBannerImage,
  createUpdateDeliveryAddressApi,
  getSingleStoreDetailApi,

  removeAddressApi,
} from "../api";

function* getBannerImageAll({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;

  try {
    const response = yield call(getBannerImage);
    const bannerImages = response.data.data;

    onSuccess(bannerImages);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* getAllDeliveryAddresses({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;
  try {
    const response = yield call(getAllDeliveryAddress);
    const deliveryAddresses = response.data.data;

    onSuccess(deliveryAddresses);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* getStoreAll({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;
  try {
    const response = yield call(getStores);
    const deliveryAddresses = response.data;

    onSuccess(deliveryAddresses);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* getDeliveryAddress({ payload = {} }: any): any {
  const { id, onSuccess, onFailure } = payload;

  try {
    const response = yield call(getDeliveryAddressApi, { id });
    const deliveryAddress = response.data.data;

    onSuccess(deliveryAddress);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* createUpdateDeliveryAddress({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  try {
    // Modify the data object if uuid === "create", otherwise, use it as it is
    const requestData = data.uuid === "create" ? { ...data, uuid: null } : data;

    const response = yield call(createUpdateDeliveryAddressApi, requestData);
    const deliveryAddress = response.data.data;

    onSuccess(deliveryAddress);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* getTokenizedCard({ payload = {} }: any): any {
  const { userUuid, onSuccess, onFailure } = payload;

  try {
    const response = yield call(getTokenizedCards, { userUuid });
    const cardList = response.data.data;
    onSuccess(cardList);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* removeTokenizedCard({ payload = {} }: any): any {
  const { uuid, onSuccess, onFailure } = payload;

  try {
    yield call(removeTokenizedCards, { uuid });
    onSuccess({});
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* createTokenizedCard({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  try {
    const response = yield call(tokenizeCards, { ...data });
    const cardList = response.data;
    onSuccess(cardList);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}



function* getSingleStoreDetail({ payload = {} }: any): any {
  const { id, onSuccess, onFailure } = payload;

  try {
    const response = yield call(getSingleStoreDetailApi, { id });
    const cardList = response.data.data;
    onSuccess(cardList);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* removeAddress({ payload = {} }: any): any {
  const { userUuid, onSuccess, onFailure } = payload;

  try {
    const removeAddressResponse = yield call(removeAddressApi, {
      userUuid,
    });
    const user = removeAddressResponse.data.data;
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* getstoreGroupsName({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;
  try {
    const response = yield call(getstoreGroupsNames);
    const storeGroupsName = response.data.data;

    onSuccess(storeGroupsName);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* getClientData({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;
  try {
    const response = yield call(ClientData);
    const clientData = response.data.data;

    onSuccess(clientData);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* getFaq({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;

  try {
    const response = yield call(getFaqData);

    const faqData = response?.data?.data;

    onSuccess(faqData);
  } catch (e: any) {
    console.log("GET_FAQ_DATA", e);

    if (onFailure) {
      onFailure(e);
    }
  }
}
export {
  getFaq,
  getBannerImageAll,
  getAllDeliveryAddresses,
  getDeliveryAddress,
  createUpdateDeliveryAddress,
  getTokenizedCard,
  createTokenizedCard,
  getSingleStoreDetail,
  removeTokenizedCard,
  removeAddress,
  getstoreGroupsName,
  getStoreAll,
  getClientData,
};
