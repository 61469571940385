import { call, put } from "redux-saga/effects";
import sha256 from "sha256";
import { setCookie, setUser } from "../../utils/commonFunction";
import { authActions } from "../actions";
import {
  authForgetPassword,
  login,
  resetPassword,
  resetPasswordQuery,
  sendOTP,
  forgotPasswordOTP,
  updateNumber,
  currentUserDetail,
  signup,
  updateUserDetail,
  otpVerifiedd,
  resetPasswordOtpVerificationn,
  UserOrderHistory,
  loginQueryApi,
  resetPasswordEmail,
} from "../api";
import { clearPreAuthedToken, clearResetToken, getPreAuthedToken, getResetToken, setPreAuthedToken } from "./helper";
import { AxiosError } from "axios";

function* authForgetPasswordSaga({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  try {
    const authByIdResponse = yield call(authForgetPassword, data);
    const user = authByIdResponse.data.data;

    // if(1) yield put (authActions.authUpdate({ admin: user }))

    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }

  yield put(authActions.authForgetPasswordComplete());
}

function* authLoginSaga({ payload = {} }: any): any {
  // login


  const { data, onSuccess, onFailure } = payload;
  const { email, password } = data;

  try {
    console.log("before")

    const authByCodeResponse = yield call(login, {
      email: email.trim(),
      password: sha256(password.trim()),
    });

    if (authByCodeResponse instanceof AxiosError) {
      onFailure(authByCodeResponse.response)
      return
    }

    const user = authByCodeResponse?.data?.data;
    if(authByCodeResponse?.status === 200 && user) {
        const mode = user["mode"]
        if (mode === "capture-phone") {
          setPreAuthedToken(user.preauthToken)
        } else if (mode === "logged") {
          clearPreAuthedToken()
          yield put(authActions.authUpdate({ user }));
          const userAuth = {
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
          };
          setCookie("dxs", userAuth.accessToken, 30);
          setUser(userAuth);
        }
        onSuccess(user)
    } else {
      onFailure(authByCodeResponse)
    }

    console.log("api/responese", user)


    
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }

  yield put(authActions.authLoginComplete());
}

function* authResetPassword({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;
  const { password, token } = data;


  try {
    const authByCodeResponse = yield call(resetPassword, {
      newPassword: sha256(password.trim()),
      token: token,
    });
    const user = authByCodeResponse.data.data;

    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* authResetPasswordEmail({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;
  const { password, token } = data;


  try {
    const authByCodeResponse = yield call(resetPasswordEmail, {
      newPassword: sha256(password.trim()),
      token: token,
    });
    const user = authByCodeResponse.data.data;

    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}


function* authResetPasswordResetQuery({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  try {
    const authByCodeResponse = yield call(resetPasswordQuery, { ...data });
    const user = authByCodeResponse.data.data;
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* loginQuery({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  try {
    const authByCodeResponse = yield call(loginQueryApi, { ...data });
    const user = authByCodeResponse.data.data;
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}


function* authSendOTP({ payload = {} }: any): any {
  const { requestData, onSuccess, onFailure } = payload;

  const pre_auth_token = getPreAuthedToken()

  try {
    const authByCodeResponse = yield call(sendOTP, { pre_auth_token });
    const user = authByCodeResponse.data.data;
    // const decryptData = decrypt(user)
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* sendForgotPasswordOTP({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  try {
    const authByCodeResponse = yield call(forgotPasswordOTP, {
      ...data,
    });

    if (authByCodeResponse instanceof AxiosError) {
      onFailure(authByCodeResponse.response);
      return
    }

    const user = authByCodeResponse.data;


    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* updatePhoneNumber({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;

  const pre_auth_token = getPreAuthedToken()

  try {
    const authByCodeResponse = yield call(updateNumber, { ...data, pre_auth_token });
    if (authByCodeResponse instanceof AxiosError) {
      onFailure(authByCodeResponse.response);
      return
    }



    const user = authByCodeResponse.data.data;
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* getCurrentUserDetail({ payload = {} }: any): any {
  const { onSuccess, onFailure } = payload;

  try {
    const authByCodeResponse = yield call(currentUserDetail);
    const user = authByCodeResponse.data.data;

    if (user) {
      yield put(authActions.authUpdate({ currentUserDetail: user }));
    }
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* authSignup({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;


  try {
    const authByCodeResponse = yield call(signup, {
      ...data,
      password: sha256(data.password.trim()),
      password_confirm: sha256(data.password_confirm.trim()),
    });

    const payload = authByCodeResponse.data
    if (authByCodeResponse instanceof AxiosError) {
      onFailure(authByCodeResponse.response)
      return
    }
    
    if (!payload.success) {
      onFailure(authByCodeResponse)
      return
    }
    

    const user = payload.data;

    const mode = user["mode"]
    if (mode === "capture-phone" || mode === "already-user-capture-phone") {
      setPreAuthedToken(user["preauthToken"])
    }

    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* updateCurrentUserDetail({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;
  const { email, cellphone, id, ...updatedData } = data;
  try {
    const authByCodeResponse = yield call(updateUserDetail, {
      ...updatedData,
    });
    const user = authByCodeResponse.data.data;
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* otpVerified({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;
  const otp = data?.verifcationCode;
  const cellphone = data?.phone;
  const ipaddr = data?.ipaddr

  const pre_auth_token = getPreAuthedToken()

  try {
    const authByCodeResponse = yield call(otpVerifiedd, { otp, cellphone, ipaddr, pre_auth_token });
    if (authByCodeResponse instanceof AxiosError) {
      onFailure(authByCodeResponse.response)
      return
    }

    const user = authByCodeResponse?.data?.data;

    if (user) {
      yield put(authActions.authUpdate({ user }));
      const userAuth = {
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
      };
      setUser(userAuth);
      clearPreAuthedToken()
    }
    setCookie("dxs", user.accessToken, 30);
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}
function* resetPasswordOtpVerification({ payload = {} }: any): any {
  const { data, onSuccess, onFailure } = payload;
  const otp = data.verifcationCode;
  const cellphone = data.phone;
  const token = getResetToken()

  try {
    const authByCodeResponse = yield call(resetPasswordOtpVerificationn, {
      otp,
      cellphone,
      token: token.token,
    });
    const user = authByCodeResponse.data.data;
    clearResetToken()
    onSuccess(user);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

function* currentUserOrderHistory({ payload = {} }: any): any {
  const { userId, onSuccess, onFailure } = payload;
  try {
    const userOrderHistoryResponse = yield call(UserOrderHistory, {
      userId,
    });
    const userOrderHistory = userOrderHistoryResponse.data;

    if (userOrderHistory) {
      yield put(
        authActions.authUpdate({ currentUserOrderHistory: userOrderHistory })
      );
    }
    onSuccess(userOrderHistory);
  } catch (e: any) {
    if (onFailure) {
      onFailure(e);
    }
  }
}

export {
  authLoginSaga,
  authForgetPasswordSaga,
  authResetPasswordEmail,
  authResetPassword,
  authResetPasswordResetQuery,
  loginQuery,
  authSendOTP,
  authSignup,
  otpVerified,
  getCurrentUserDetail,
  updateCurrentUserDetail,
  updatePhoneNumber,
  currentUserOrderHistory,
  resetPasswordOtpVerification,
  sendForgotPasswordOTP,
};
