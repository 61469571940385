const getHome = (state: any) => state.auth.home;
const userSearchAddress = (state: any) => state.home.userSearchAddress;
const getStore = (state: any) => state.home.store;
const defaultPriceType = (state: any) => state.home.defaultPriceType;
const UpdateOrderStatusPolling = (state: any) =>
  state.home.UpdateOrderStatusPolling;

export {
  getHome,
  getStore,
  userSearchAddress,
  defaultPriceType,
  UpdateOrderStatusPolling,
};
