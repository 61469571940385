import React, { useEffect, useState } from "react";
import { withRouter } from "next/router";
import * as Sentry from '@sentry/nextjs';
import type { NextPageContext } from "next";


const ErrorBoundary = ({ children }: any) => {
  const [error, setError] = useState<Error | null>(null);
  const [errorInfo, setErrorInfo] = useState<React.ErrorInfo | null>(null);

  useEffect(() => {
    const errorHandler = (error: Error, errorInfo: React.ErrorInfo) => {
      setError(error);
      setErrorInfo(errorInfo);
    };

    window.addEventListener('error', (event) => errorHandler(event.error, { componentStack: '' }));
    return () => {
      window.removeEventListener('error', (event) => errorHandler(event.error, { componentStack: '' }));
    };
  }, []);

  if (errorInfo) {
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: "pre-wrap" }}>
          {error?.toString()}
          <br />
          {errorInfo.componentStack}
        </details>
        <button
          type="button"
          onClick={() => {
            setError(null);
            setErrorInfo(null);
          }}
        >
          Try again?
        </button>
      </div>
    );
  }

  return <>{children}</>;
};


ErrorBoundary.getInitialProps = async (contextData: NextPageContext) => {
  console.log("@_error/getInitialProps")
  await Sentry.captureUnderscoreErrorException(contextData);
}



export default withRouter(ErrorBoundary);
