const PRE_AUTH_KEY = "_pre_authed_token_"
const IS_PASSWORD_CAPTURE_KEY = "_reset_password_capture_"

export const setPreAuthedToken = (value: string) => {
    localStorage.setItem(PRE_AUTH_KEY, value)


}

export const getPreAuthedToken = () => {
    return localStorage.getItem(PRE_AUTH_KEY)
}

export const clearPreAuthedToken = () => {
    localStorage.removeItem(PRE_AUTH_KEY)
}

const RESET_TOKEN = "_reset_token_"

export const getResetToken = () => {
    return {
        token: localStorage.getItem(RESET_TOKEN),
        isPasswordCapture:  localStorage.getItem(IS_PASSWORD_CAPTURE_KEY) === "true"
    }
}

export const setResetToken = (value: string, isCapturePass?: boolean) => {
    localStorage.setItem(RESET_TOKEN, value)

    if (isCapturePass) {
        localStorage.setItem(IS_PASSWORD_CAPTURE_KEY, "true")
    }
}

export const clearResetToken = () => {
    localStorage.removeItem(PRE_AUTH_KEY)
    localStorage.removeItem(IS_PASSWORD_CAPTURE_KEY)
}



