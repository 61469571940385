
const getUser = (state:any)=> state.auth.user;
const getProcessingLogin = (state:any)=> state.auth.processingLogin;
const getProcessingForgetPassword = (state: any) => state.auth.processingForgetPassword;
const getProcessingResetPassword = (state: any) => state.auth.processingResetPassword;
const getFirstTimeSignUpFlag = (state: any) => state.auth.firstTimeSignup;
const getPasswordResetFlag = (state:any) => state.auth.passwordResetFlag;
const getCurrentUserDetail = (state:any) => {

  const detail = state.auth.currentUserDetail

  if (detail?.cellphone && detail?.countryCode) {
    detail.cellphone = unformatPhonenumber(detail.countryCode, detail.cellphone)
  }

  return detail
};

export {
  getUser,
  getProcessingLogin,
  getProcessingForgetPassword,
  getProcessingResetPassword,
  getFirstTimeSignUpFlag,
  getPasswordResetFlag,
  getCurrentUserDetail
};


const unformatPhonenumber = (coutryCode: string, phone: string) => {
  if (phone.startsWith(coutryCode) && phone.length > 10) {
      phone = phone.substring(coutryCode.length);
      phone = '0' + phone;
  }


  return phone
}