import * as types from "./types";

const INITIAL_STATE = {
  user: {},
  currentUserDetail: {},
  firstTimeSignup: false,
  passwordResetFlag: false,
  processingForgetPassword: false,
  processingLogin: false,
  processingResetPassword: false,
  currentUserOrderHistory: {},
};

const authReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case types.AUTH_CLEAR:
      return { ...INITIAL_STATE}
    case types.AUTH_UPDATE:
      return {
        ...state,
        user: action.payload.user || state.user,
        currentUserDetail:
          action.payload.currentUserDetail || state.currentUserDetail,
        currentUserOrderHistory:
          action.payload.currentUserOrderHistory ||
          state.currentUserOrderHistory,
      };
    case types.FIRST_TIME_SIGNUP:
      return {
        ...state,
        firstTimeSignup: action.payload.firstTimeSignup,
      };
    case types.PASSWORD_RESET_FLAG:
      return {
        ...state,
        passwordResetFlag: action.payload.passwordResetFlag,
      };
    case types.OTP_IS_VERIFIED:
    case types.AUTH_SIGNUP:
    case types.UPDATE_PHONE:
    case types.UPDATE_USER_DETAIL:
    case types.AUTH_FORGET_PASSWORD_REQUEST:
    case types.AUTH_LOGIN_REQUEST:
      return {
        ...state,
        processingForgetPassword: true,
        processingLogin: true,
      };
    case types.AUTH_FORGET_PASSWORD_COMPLETE:
    case types.AUTH_LOGIN_COMPLETE:
      return {
        ...state,
        processingForgetPassword: false,
        processingLogin: false,
      };
    case types.AUTH_RESET_PASSWORD_REQUEST:
    case types.AUTH_RESET_PASSWORD_REQUEST_EMAIL:
      return {
        ...state,
        processingResetPassword: true,
      };

    case types.AUTH_RESET_PASSWORD_QUERY_REQUEST:
    case types.AUTH_SEND_OTP:
    case types.AUTH_RESET_PASSWORD_COMPLETE:
      return {
        ...state,
        processingResetPassword: false,
      };
    case types.CURRENT_USER_ORDER_HISTORY:
    default:
      return state;
  }
};

export default authReducer;
