import { all, takeEvery, takeLatest } from "redux-saga/effects";
import {
  authLoginSaga,
  authForgetPasswordSaga,
  authResetPassword,
  authSendOTP,
  updatePhoneNumber,
  authResetPasswordResetQuery,
  loginQuery,
  authSignup,
  otpVerified,
  resetPasswordOtpVerification,
  getCurrentUserDetail,
  updateCurrentUserDetail,
  currentUserOrderHistory,
  authResetPasswordEmail,
  sendForgotPasswordOTP,
} from "./auth/sagas";
import { authTypes, homeTypes } from "./types";
import {
  createUpdateDeliveryAddress,
  getAllDeliveryAddresses,
  getBannerImageAll,
  getDeliveryAddress,
  getTokenizedCard,
  createTokenizedCard,
  getSingleStoreDetail,
  removeAddress,
  removeTokenizedCard,
  getstoreGroupsName,
  getStoreAll,
  getClientData,
  getFaq,
} from "./home/sagas";




export function* rootSaga() {
  yield all([
    takeEvery(authTypes.AUTH_LOGIN_REQUEST, authLoginSaga),
    takeLatest(authTypes.AUTH_FORGET_PASSWORD_REQUEST, authForgetPasswordSaga),
    takeLatest(authTypes.AUTH_RESET_PASSWORD_REQUEST, authResetPassword),
    takeLatest(authTypes.AUTH_RESET_PASSWORD_REQUEST_EMAIL, authResetPasswordEmail),
    takeLatest(
      authTypes.AUTH_RESET_PASSWORD_QUERY_REQUEST,
      authResetPasswordResetQuery
    ),
    takeLatest(authTypes.LOGIN_QUERY, loginQuery),
    takeLatest(authTypes.AUTH_SEND_OTP, authSendOTP),
    takeLatest(authTypes.AUTH_SEND_OTP_ON_FORGOT, sendForgotPasswordOTP),

    takeLatest(authTypes.UPDATE_PHONE, updatePhoneNumber),
    takeLatest(authTypes.AUTH_SIGNUP, authSignup),
    takeEvery(authTypes.OTP_IS_VERIFIED, otpVerified),
    takeEvery(
      authTypes.RESET_PASSWORD_OTP_IS_VERIFIED,
      resetPasswordOtpVerification
    ),
    takeEvery(authTypes.CURRENT_USER_DETAIL, getCurrentUserDetail),
    takeEvery(authTypes.UPDATE_USER_DETAIL, updateCurrentUserDetail),

    takeEvery(homeTypes.ALL_HOME_PAGE_CONTENT_REQUEST, getBannerImageAll),
    takeEvery(homeTypes.GET_DELIVERY_ADDRESSES, getAllDeliveryAddresses),
    takeEvery(homeTypes.GET_DELIVERY_ADDRESS, getDeliveryAddress),
    takeLatest(homeTypes.GET_TOKENIZED_CARDS, getTokenizedCard),
    takeLatest(homeTypes.REMOVE_TOKENIZED_CARDS, removeTokenizedCard),
    takeLatest(homeTypes.CREATE_TOKENIZED_CARD, createTokenizedCard),
    takeLatest(homeTypes.SINGLE_STORE_DETAIL, getSingleStoreDetail),
    takeLatest(homeTypes.GET_STORE_GROUP_NAME, getstoreGroupsName),
    takeLatest(homeTypes.GET_FAQ_DATA, getFaq),
    takeEvery(homeTypes.SUBMIT_DELIVERY_ADDRESS, createUpdateDeliveryAddress),
    takeLatest(homeTypes.REMOVE_ADDRESS, removeAddress),
    takeLatest(authTypes.CURRENT_USER_ORDER_HISTORY, currentUserOrderHistory),
    takeEvery(homeTypes.GET_STORE_ALL, getStoreAll),
    takeEvery(homeTypes.GET_CLIENT_DATA, getClientData),
  ]);
}
