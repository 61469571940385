import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";
import config from "../config/app";
import { createStore, applyMiddleware } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import { Persistor } from "redux-persist/es/types";

let storeCopy: any;
let persistor: Persistor

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);

  persistor = persistStore(store);
  // delete cached data
  if (config.persistorPurge) {
    persistor.purge();
  }

  storeCopy = store;

  return {
    store,
    persistor,
  };
};

export const reduxPersistor = () => persistor;

export const reduxHandler = () => storeCopy;

export const reduxStore = () => storeCopy.getState();

export default configureStore;
