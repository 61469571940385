import {
  OTP_IS_VERIFIED,
  RESET_PASSWORD_OTP_IS_VERIFIED,
  UPDATE_PHONE,
  CURRENT_USER_DETAIL,
  FIRST_TIME_SIGNUP,
  UPDATE_USER_DETAIL,
  PHONE_IS_VERIFIED,
  AUTH_FORGET_PASSWORD_REQUEST,
  PASSWORD_RESET_FLAG,
  AUTH_FORGET_PASSWORD_COMPLETE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_COMPLETE,
  AUTH_RESET_PASSWORD_COMPLETE,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_QUERY_REQUEST,
  AUTH_SEND_OTP,
  AUTH_SEND_OTP_ON_FORGOT,
  AUTH_SIGNUP,
  CURRENT_USER_ORDER_HISTORY,
  AUTH_UPDATE,
  LOGIN_QUERY,
  AUTH_RESET_PASSWORD_REQUEST_EMAIL,
  AUTH_CLEAR,
} from "./types";

const authUpdate = (payload: any) => ({
  type: AUTH_UPDATE,
  payload,
});

const authClear = () => ({
  type: AUTH_CLEAR
});


const otpVerified = (payload: any) => ({
  type: OTP_IS_VERIFIED,
  payload,
});
const resetPasswordOtpVerification = (payload: any) => ({
  type: RESET_PASSWORD_OTP_IS_VERIFIED,
  payload,
});

const updatePhoneNumber = (payload: any) => ({
  type: UPDATE_PHONE,
  payload,
});

const getCurrentUserDetail = (payload: any) => ({
  type: CURRENT_USER_DETAIL,
  payload,
});

const firstTimeSignUp = (payload: any) => ({
  type: FIRST_TIME_SIGNUP,
  payload,
});

const updateCurrentUserDetail = (payload: any) => ({
  type: UPDATE_USER_DETAIL,
  payload,
});


const passwordResetFlag = (payload: any) => ({
  type: PASSWORD_RESET_FLAG,
  payload,
});

const authForgetPassword = (payload: any) => ({
  type: AUTH_FORGET_PASSWORD_REQUEST,
  payload,
});

const authForgetPasswordComplete = () => ({
  type: AUTH_FORGET_PASSWORD_COMPLETE,
});

const authLogin = (payload: any) => ({
  type: AUTH_LOGIN_REQUEST,
  payload,
});

const authLoginComplete = () => ({
  type: AUTH_LOGIN_COMPLETE,
});

const authResetPasswordComplete = () => ({
  type: AUTH_RESET_PASSWORD_COMPLETE,
});

const authResetPassword = (payload: any) => ({
  type: AUTH_RESET_PASSWORD_REQUEST,
  payload,
});

const authResetPasswordEmail = (payload: any) => ({
  type: AUTH_RESET_PASSWORD_REQUEST_EMAIL,
  payload,
});

const authResetPasswordQuery = (payload: any) => ({
  type: AUTH_RESET_PASSWORD_QUERY_REQUEST,
  payload,
});
const loginQuery = (payload: any) => ({
  type: LOGIN_QUERY,
  payload,
});

const authSendOTP = (payload: any) => ({
  type: AUTH_SEND_OTP,
  payload,
});
const sendForgotPasswordOTP = (payload: any) => ({
  type: AUTH_SEND_OTP_ON_FORGOT,
  payload,
});
const authSignUp = (payload: any) => ({
  type: AUTH_SIGNUP,
  payload,
});

const getCurrentUserOrderHistory = (payload: any) => ({
  type: CURRENT_USER_ORDER_HISTORY,
  payload,
});

export {
  authClear,
  loginQuery,
  authLogin,
  authLoginComplete,
  authSignUp,
  firstTimeSignUp,
  authForgetPassword,
  passwordResetFlag,
  authForgetPasswordComplete,
  authResetPasswordEmail,
  authResetPassword,
  authResetPasswordComplete,
  authResetPasswordQuery,
  authUpdate,
  otpVerified,
  authSendOTP,
  getCurrentUserDetail,
  updateCurrentUserDetail,
  updatePhoneNumber,
  getCurrentUserOrderHistory,
  resetPasswordOtpVerification,
  sendForgotPasswordOTP,
};
