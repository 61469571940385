import styles from "./styles.module.scss";
import Image from "next/legacy/image";
import Button from "../../components/ui-changes/button";
import Overlay from "../../components/overlay";
import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { homeActions, homeSelectors } from "../../state/home";
import { isEmpty } from "lodash";
import { authActions, authSelectors } from "../../state/auth";
import { removeUser } from "../../utils/commonFunction";
import { logoSizes } from "../../utils/imgUtils";
import { errors } from "../../constants";
import Cookies from "js-cookie";
import { useCart } from "../MenuItemRenderer/SuperCart";
import { useClient } from "../../hooks/useClientState";
import cloudflareLoader from "../../Scripts/cloudflareCustomImageLoader";

const FallbackLogo =
  "https://serveup.ams3.cdn.digitaloceanspaces.com/demo/images/simplePlaceholder.png";

const Nav = (props: any) => {
  //state for overlay
  const {
    selectedStore,
    order,
    loginUser,
    authUpdate,
    firstTimeSignUp,
    updateStore,
  } = props;

  const router = useRouter();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [logStatus, setLogStatus] = useState(false);

  const cart = useCart();
  const cdata = useClient();

  const logoIcon = cdata?.data?.clientMeta?.styleJson?.LogoIcon;

  useEffect(() => {
    if (!isEmpty(loginUser) || !isEmpty(Cookies.get("dxs"))) {
      setLogStatus(true);
    } else {
      setLogStatus(false);
    }
  }, [props.loginUser]);

  const navigateToProfile = (link: any) => {
    const { firstTimeSignUp, firstTimeSignUpFlag } = props;
    if (firstTimeSignUpFlag) {
      firstTimeSignUp({ firstTimeSignup: false });
    }
    router.push(link);
  };

  const onLogout = () => {
    router.push("/login");
    const { firstTimeSignUp, firstTimeSignUpFlag } = props;
    if (firstTimeSignUpFlag) {
      firstTimeSignUp({ firstTimeSignup: false });
    }
    authUpdate({ user: {} });
    removeUser();
    Cookies.remove("dxs");
    cart.clearItems();

    updateStore({ store: {} });
    setLogStatus(false);
  };

  const [menuLink, setMenuLink] = useState("/store-listing");

  useEffect(() => {
    const modifiedStoreName = selectedStore?.name
      ?.toLowerCase()
      .replace(/\s+/g, "-");
    if (modifiedStoreName) {
      setMenuLink(`/menu/${modifiedStoreName}/${selectedStore.uuid}`);
    }
  }, [selectedStore]);

  return (
    <>
      <div className="fixed w-full md:min-w-768 z-[100]">
        <div
          className={"fixed bg-main w-full min-w-768 h-[3rem] md:h-[4.5rem]"}
        />
        <div className="relative flex px-Spacing-7 justify-between self-stretch items-start md:max-w-screen-xl mx-auto">
          <div className="flex items-center gap-x-Spacing-5">
            <Link prefetch={false} href={"/"}>
              <div className="relative size-20 my-1">
                <Image
                  loader={cloudflareLoader}
                  src={logoIcon || FallbackLogo}
                  layout="fill"
                  objectFit="cover"
                  alt="logo image"
                  sizes={logoSizes()}
                />
              </div>
            </Link>
            <div className="hidden md:flex md:h-7 md:gap-x-Spacing-5 md:text-start md:items-start">
              <h6 className="text-white font-mont2 text-xs font-black tracking-[.188em]">
                <Link href={menuLink}>VIEW MENU</Link>
              </h6>
              <h6 className="text-white font-mont2 text-xs font-black tracking-[.188em]">
                <Link href={"/store-locator"}>SELECT A STORE</Link>
              </h6>
            </div>
          </div>
          <div className="flex gap-x-Spacing-5 items-start md:items-center h-[4.5rem] pt-Spacing-4 md:pt-0">
            {!logStatus ? (
              <>
                <Link href="/login" 
                  className="hidden md:flex justify-center bg-Colors-Accent-Accent-9 rounded-Radius-2-max h-Tokens-Space-button-height-2 items-center gap-Spacing-2 px-Spacing-3 text-Colors-Neutral-Neutral-1 font-mont2 font-medium text-sm w-[4.375rem] whitespace-nowrap"
                  >
                  Login
                </Link>
                <Link href="/signup" className="hidden md:flex justify-center bg-main rounded-Radius-2-max border-Colors-Neutral-Neutral-1 border-[.063rem] h-Tokens-Space-button-height-2 items-center gap-Spacing-2 px-Spacing-3 text-Colors-Neutral-Neutral-1 font-mont2 font-medium text-sm w-[4.375rem] whitespace-nowrap">
                  Sign up
                </Link>
              </>
            ) : (
              <>
                <button 
                  className="hidden md:flex justify-center bg-Colors-Accent-Accent-9 rounded-Radius-2-max h-Tokens-Space-button-height-2 items-center gap-Spacing-2 px-Spacing-3 text-Colors-Neutral-Neutral-1 font-mont2 font-medium text-sm w-[4.375rem] whitespace-nowrap"
                  onClick={() => onLogout()}
                  >
                  Logout
                </button>
              </>
            )}
            <div className="relative h-[16px] w-[18px]">
              <Link href={menuLink}>
                <svg className="w-full h-full">
                  <use href="/sprites/sprites.svg#cart" />
                </svg>

                {!!Object.keys(cart.state.items).length && (
                  <div className={styles.redDot}></div>
                )}
              </Link>
            </div>
            <div
              className="relative h-Spacing-4 w-Spacing-4 md:hidden"
              onClick={() => setOverlayOpen(true)}
            >
              <svg className="w-full h-full">
                <use href="/sprites/sprites.svg#hamburger" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  authUpdate: (payload: any) => dispatch(authActions.authUpdate(payload)),
  firstTimeSignUp: (params: any) =>
    dispatch(authActions.firstTimeSignUp(params)),
  updateStore: (params: any) => dispatch(homeActions.storeUpdate(params)),
});

const mapStateToProps = (state: any) => ({
  selectedStore: homeSelectors.getStore(state),
  loginUser: authSelectors.getUser(state),
  firstTimeSignUpFlag: authSelectors.getFirstTimeSignUpFlag(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
