import "../styles/globals.scss";
import "../styles/_colors.scss";
import styles from "../styles/Home.module.css";
import ErrorBoundary from "./_error";
import React from "react";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import configureStore from "../state/configureStore";
import Nav from "../containers/Nav";
import Footer from "../containers/Footer";
const { persistor, store } = configureStore();
import { useRouter } from "next/router";
import { decrypt } from "../utils/commonFunction";
import WithMainModal from "../components/modal/WithMainModal";
import SuperCartProvider from "../containers/MenuItemRenderer/SuperCart/Provider";
import { ClientContext, useClientState } from "../hooks/useClientState";
import { APIProvider as GoogleAPIProvider } from "@vis.gl/react-google-maps";
import config from "../config/app";

const { ECommerce } = config;

declare global {
  interface Window {
    log_override_override?: boolean;
    __d__: any;
  }
}

const maybeLog =
  (func: Function) =>
  (...args: any[]) => {
    if (typeof window !== "undefined" && window["log_override_override"]) {
      func(...args);
    }
  };

const OverRideLogger = () => {
  console.error = maybeLog(console.error);
  console.log = maybeLog(console.log);
  console.debug = maybeLog(console.debug);
  console.warn = maybeLog(console.warn);
};

function MyApp({ Component, pageProps }: AppProps) {
  // OverRideLogger()

  if (typeof window !== "undefined") {
    window["__d__"] = decrypt;
  }

  // const order = {
  //   status: "Preparing",
  //   time: "09:20",
  //   store: "Roman’s Pizza Milnerton",
  // };

  const router = useRouter();
  const ClientData = useClientState();

  console.log("@debug_cdata", ClientData)

  

  return (
    <div style={ClientData.style}>
      <ErrorBoundary>
        <Provider store={store}>
          <GoogleAPIProvider apiKey={ECommerce["GOOGLE_MAPS_KEY"] as string}>
            <ClientContext.Provider value={ClientData}>
              <SuperCartProvider>
                <WithMainModal>
                  <div style={ClientData.style}>
                    <Nav pathname={router.pathname} />

                    <div className={styles.orderHolder}>
                      <Component {...pageProps} />
                    </div>
                    <Footer />
                  </div>
                </WithMainModal>
              </SuperCartProvider>
            </ClientContext.Provider>
          </GoogleAPIProvider>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default MyApp;
