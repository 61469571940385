const HOME_UPDATE = "HOME_UPDATE";
const ALL_HOME_PAGE_CONTENT_REQUEST = "ALL_HOME_PAGE_CONTENT_REQUEST";
const STORE_UPDATE = "STORE_UPDATE";
const SET_USER_ADDR_ID = "SET_USER_ADDR_ID";
const GET_DELIVERY_ADDRESSES = "GET_DELIVERY_ADDRESSES";
const GET_DELIVERY_ADDRESS = "GET_DELIVERY_ADDRESS";
const SUBMIT_DELIVERY_ADDRESS = "SUBMIT_DELIVERY_ADDRESS";
const USER_SEARCH_ADDRESS = "USER_SEARCH_ADDRESS";
const GET_TOKENIZED_CARDS = "GET_TOKENIZED_CARDS";
const CREATE_TOKENIZED_CARD = "CREATE_TOKENIZED_CARD";
const ORDER_STATUS_POLLING = "ORDER_STATUS_POLLING";
const SINGLE_STORE_DETAIL = "SINGLE_STORE_DETAIL";
const REMOVE_ADDRESS = "REMOVE_ADDRESS";
const REMOVE_TOKENIZED_CARDS = "REMOVE_TOKENIZED_CARDS";
const GET_STORE_GROUP_NAME = "GET_STORE_GROUP_NAME";
const GET_STORE_ALL = "GET_STORE_ALL";
const GET_CLIENT_DATA = "GET_CLIENT_DATA";
const UPDATE_ORDER_STATUS_POLLING = "UPDATE_ORDER_STATUS_POLLING";
const GET_FAQ_DATA = "GET_FAQ_DATA";

export {
  GET_FAQ_DATA,
  HOME_UPDATE,
  ALL_HOME_PAGE_CONTENT_REQUEST,
  STORE_UPDATE,
  SET_USER_ADDR_ID,
  GET_DELIVERY_ADDRESSES,
  GET_DELIVERY_ADDRESS,
  SUBMIT_DELIVERY_ADDRESS,
  USER_SEARCH_ADDRESS,
  GET_TOKENIZED_CARDS,
  CREATE_TOKENIZED_CARD,
  ORDER_STATUS_POLLING,
  SINGLE_STORE_DETAIL,
  REMOVE_ADDRESS,
  REMOVE_TOKENIZED_CARDS,
  GET_STORE_ALL,
  GET_STORE_GROUP_NAME,
  GET_CLIENT_DATA,
  UPDATE_ORDER_STATUS_POLLING,
};
