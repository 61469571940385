import {
  HOME_UPDATE,
  UPDATE_ORDER_STATUS_POLLING,
  STORE_UPDATE,
  GET_STORE_GROUP_NAME,
  GET_STORE_ALL,
  GET_DELIVERY_ADDRESSES,
  GET_DELIVERY_ADDRESS,
  SUBMIT_DELIVERY_ADDRESS,
  SET_USER_ADDR_ID,
} from "./types";

const INITIAL_STATE = {
  home: {},
  store: {},
  orderType: "",
  userSearchAddress: {},
  defaultPriceType: "priceCollection",
  UpdateOrderStatusPolling: 0,
  userDeliveryAddrId: undefined
};

const authReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case HOME_UPDATE:
      return {
        ...state,
        home: action.payload.home || state.home,
        orderType: action.payload.orderType || state.orderType,
        userSearchAddress:
          action.payload.userSearchAddress || state.userSearchAddress,
      };
    case UPDATE_ORDER_STATUS_POLLING:
      return {
        ...state,
        UpdateOrderStatusPolling: action.payload || state,
      };
    case  SET_USER_ADDR_ID:
      return {
        ...state,
        userDeliveryAddrId: action.payload.userDeliveryAddrId,
      };
  
    case STORE_UPDATE:
      return {
        ...state,
        store: action.payload.store || state.store,
        defaultPriceType: action.payload.serviceType,
      };
    case GET_STORE_GROUP_NAME:
      return {
        ...state,
        // store: action.payload || state,
      };
    case GET_STORE_ALL:
    case GET_DELIVERY_ADDRESSES:
    case GET_DELIVERY_ADDRESS:
    case SUBMIT_DELIVERY_ADDRESS:

    default:
      return state;
  }
};

export default authReducer;
