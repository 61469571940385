import { useRouter } from "next/router";
import styles from "./styles.module.scss";
import { connect } from "react-redux";
import { authActions, authSelectors } from "../../state/auth";
import { useClient } from "../../hooks/useClientState";
import { MutableRefObject, useEffect, useRef } from "react";

const Footer = (props: any) => {
  const router = useRouter();

  const cdata = useClient();

  const termAndConditions = useRef(null);
  const franchise = useRef(null);
  const contact = useRef(null);
  const feedback = useRef(null);
  const faq = useRef(null);
  const downloadMenu = useRef(null);
  const nutritionalInfo = useRef(null);
  const privacyPolicy = useRef(null);

  const footerContent =
    cdata.data.clientMeta?.styleJson?.footerContent ||
    "Copyright © 2023 Roman's Pizza.";

  const feedbackEmail = `mailto:${
    cdata.data.clientMeta?.styleJson?.feedbackEmail ||
    "feedback@serveup.software"
  }`;

  const pageRefMaps: Record<string, MutableRefObject<null>> = {
    termAndConditions: termAndConditions,
    franchise: franchise,
    contact: contact,
    feedback: feedback,
    faq: faq,
    downloadMenu: downloadMenu,
    nutritionalInfo: nutritionalInfo,
    privacyPolicy: privacyPolicy,
  };

  useEffect(() => {
    const footerMask = cdata.data.clientMeta?.styleJson?.footerMask || [];

    footerMask.forEach((mask) => {
      const maskedRef = pageRefMaps[mask];

      const elem = maskedRef.current as unknown as HTMLElement;
      if (!elem) {
        return;
      }
      elem.hidden = true;
    });
  }, [cdata]);

  const navigateTo = (link: any) => {
    const { firstTimeSignUp, firstTimeSignUpFlag } = props;
    if (firstTimeSignUpFlag) {
      firstTimeSignUp({ firstTimeSignup: false });
    }
    // firstTimeSignUp({ firstTimeSignup: false });
    router.push(link);
  };

  return (
    <div className="flex w-full min-h-[400px] bg-main py-[23px] justify-center items-center px-Spacing-7 gap-[85px] md:min-h-[80px] ">
      <div className="flex flex-col justify-center items-center w-full gap-Spacing-7 mx-auto md:max-w-screen-xl md:flex-row md:self-stretch md:justify-between md:my-auto md:h-fit md:flex-wrap">
        <ul className="flex flex-col gap-Spacing-4 self-stretch items-start md:flex-row md:my-auto">
          <li ref={termAndConditions} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/t&c")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Ts & Cs</a>
            </div>
          </li>
          <li ref={privacyPolicy} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/privacy-policy")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Privacy Policy</a>
            </div>
          </li>
          <li ref={franchise} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/franchise/our-story")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Franchise</a>
            </div>
          </li>
          <li ref={contact} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/contact")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Contact</a>
            </div>
          </li>
          <li ref={faq} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/faq")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">FAQ</a>
            </div>
          </li>
          <li ref={downloadMenu} className="cursor-pointer">
            <div className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]">
              <a
                target="_blank"
                href="https://serveup.ams3.digitaloceanspaces.com/web-ecommerce/documents/Menu.pdf"
              >
                Downloads
              </a>
            </div>
          </li>
        </ul>

        <div className="flex gap-Spacing-7 flex-col self-stretch items-start md:flex-row">
          <p className="text-white font-mont2 text-[0.625rem] font-medium tracking-[0.063rem] md:my-auto">
            {getDateFixedCopyrightText(footerContent)}{" "}
            <br className="md:hidden" />
            All rights reserved.
          </p>
          <div className="relative h-[21px] w-[20px]">
            <svg className="w-full h-full">
              <use href="/sprites/sprites.svg#serveUp" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  firstTimeSignUp: (params: any) =>
    dispatch(authActions.firstTimeSignUp(params)),
});

const mapStateToProps = (state: any) => ({
  firstTimeSignUpFlag: authSelectors.getFirstTimeSignUpFlag(state),
});

const getDateFixedCopyrightText = (text: string) => {
  const yearRegex = /\d{4}/;
  const match = yearRegex.exec(text);
  if (match) {
    const currentYear = new Date().getFullYear();
    if (match[0] !== String(currentYear)) {
      return text.replace(yearRegex, String(currentYear));
    }
  }
  return text;
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
