import style from "./style.module.scss";

export default function Button({
  buttonRef,
  text,
  type,
  svg,
  disabled,
  func,
  buttonOrder,
  alt,
}: any) {
  //passing variables so component can be dynamic
  return (
    <div className="relative">
      {buttonOrder === 1 && (
        <div className="flex rounded-Radius-3-max absolute w-full h-full p-Spacing-1 opacity-[0.92] z-[-1] pointer-events-none">
          <span className="absolute rounded-Radius-3-max border-4 border-Colors-Accent-Accent-Alpha-8  w-[calc(100%+8px)] h-[calc(100%+8px)] left-[-4px] top-[-4px]" />
          <span className="absolute rounded-Radius-3-max border-2 border-Colors-Accent-Accent-3  w-[calc(100%+4px)] h-[calc(100%+4px)] left-[-2px] top-[-2px]" />
        </div>
      )}
      <button
        ref={buttonRef}
        disabled={disabled === null ? false : disabled}
        onClick={text === "yes" || text === "no" ? () => func(text) : func}
        className={`${alt ? "md:min-w-[272px]" : "md:min-w-[390px]"}
      md:flex justify-center cursor-pointer max-w-[329px] md:max-w-[390px] ${
        buttonOrder === 1
          ? "w-full bg-Colors-Accent-Accent-10 rounded-Radius-2-max h-Tokens-Space-button-height-2 items-center gap-Spacing-2 px-Spacing-3 text-Colors-Neutral-Neutral-1 font-mont2 font-medium text-Typography-Font-size-3 capitalize h-[40px] self-stretch disabled:bg-Colors-Neutral-Neutral-Alpha-6 disabled:text-Colors-Accent-Accent-Alpha-11"
          : buttonOrder === 2
          ? "w-full bg-Colors-Accent-Accent-9 rounded-Radius-2-max h-Tokens-Space-button-height-2 items-center gap-Spacing-2 px-Spacing-3 text-Colors-Neutral-Neutral-1 font-mont2 font-medium text-Typography-Font-size-2 capitalize h-[32px] self-stretch disabled:bg-Colors-Neutral-Neutral-Alpha-6 disabled:text-Colors-Accent-Accent-Alpha-11"
          : buttonOrder === 3
          ? "w-full border-Colors-Accent-Accent-Alpha-8 border-[1px] bg-white whitespace-nowrap rounded-Radius-2-max h-Tokens-Space-button-height-2 items-center gap-Spacing-2 px-Spacing-3 text-Colors-Accent-Accent-Alpha-11 font-mont2 font-medium text-Typography-Font-size-3 capitalize h-[40px] self-stretch disabled:bg-Colors-Neutral-Neutral-Alpha-6 disabled:text-Colors-Accent-Accent-Alpha-11"
          : ""
      }`}
        type={type}
      >
        {svg && (
          <span>
            <svg
              height="100%"
              width="100%"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 6C6.59334 6 7.17336 5.82405 7.66671 5.49441C8.16006 5.16477 8.54458 4.69623 8.77164 4.14805C8.9987 3.59987 9.05811 2.99667 8.94236 2.41473C8.8266 1.83279 8.54088 1.29824 8.12132 0.878681C7.70176 0.459123 7.16721 0.173401 6.58527 0.0576455C6.00333 -0.0581101 5.40013 0.00129984 4.85195 0.228363C4.30377 0.455426 3.83524 0.839943 3.50559 1.33329C3.17595 1.82664 3 2.40666 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V12H12V10.5C12 8.505 8.0025 7.5 6 7.5Z"
                fill="white"
              />
            </svg>
          </span>
        )}
        {text}
      </button>
    </div>
  );
}
