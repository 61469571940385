const DEVELOPMENT_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const QA_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const STAGING_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const PRODUCTION_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const timeout = 120000;

// fixme => i donot know the sensible value we cannot use node.process.env polyfill so cannot use env
const MAX_STORE_RADIUS = 100

const config = {
  showDebugUI: !!process.env.NEXT_PUBLIC_SHOW_DEBUG_UI,
  environment:process.env.NODE_ENV || "production",
  ECommerce: {
    BASE_URL: process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT,
    CUSTOMER_URL: process.env.ECOMMERCE_CUSTOMER_SERVICE_ENDPOINT,
    ORDER_URL: process.env.ECOMMERCE_ORDER_SERVICE_ENDPOINT,
    STORE_URL: process.env.ECOMMERCE_STORE_SERVICE_ENDPOINT,
    GOOGLE_MAPS_GEOCODE_URL: `https://maps.googleapis.com/maps/api/geocode/json?address`,
    GOOGLE_MAPS_KEY: process.env.GOOGLE_API_KEY,
    GOOGLE_GTMID: process.env.NEXT_PUBLIC_BIG_BROTHER_KEY, // Google analytics / tag manager
    IVERI_REQUEST_URL:
      "https://portal.nedsecure.co.za/threedsecure/EnrollmentInitial",
    IVERI_RETURN_URL: `${process.env.ECOMMERCE_ORDER_SERVICE_ENDPOINT}/updateOrderPaymentDetails`,
    IVERI_APPLICATION_ID: "a6aee1c1-78eb-4b59-a9b4-fa98c6d3adfd",
    CURRENCY: "ZAR",
    timeout,
  },
  isDev: false,
  isDevPackage: false,
  isLocal: false,
  persistorPurge: false,
  version: 10,
  customerSecretKey: process.env.CUSTOMER_SECRET,
  max_store_radius: MAX_STORE_RADIUS,
  currencySymbol: "R",
  deliveryOptions: "priceDelivery",
  EncrytionDecryptionRequired: false,
  EcommerceGroupUuid: "73d8aaf1-73fe-414e-937f-adacab451571",
  EcommerceGroupName: "E-Commerce",
  EcommerceChannelUuid: "5d477865-f11c-468c-b957-d1eb54c776d8",
  EcommerceChannelName: "Website",
  EcommnerceTakeAway: "2e092b7e-e34a-4b89-bf27-ff33da98de52",
  EcommerceDelivery: "c14e076-638f-44f1-b913-6b1fa4419eb7",
  EcommerceCashUuid: "3e03b93a-f60a-4307-a040-6d49fe47c629",
  EcommerceCardUuid: "2a971c1c-40f9-40d7-be93-e5af9e60cb2d",
  EcommerceCashName: "Cash",
  EcommerceCardName: "Card",
  EcommerceEFTName: "EFT",
  EcommerceServiceNameDelivery: "Delivery",
  EcommerceServiceNameTakeAway: "Take Away",
  Delivery: "4e3d26a1-27e1-4c31-8c1d-32f3e9c4h75g",
  Collection: "4e3d26a1-27e1-4c31-8c1d-32f3e9c4hue7",
};



const getConfig = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      config.ECommerce.BASE_URL = DEVELOPMENT_BASE_URL;
      config.isDev = true;
      return config
    case "qa":
    case "preview":
      config.ECommerce.BASE_URL = config.ECommerce.BASE_URL || QA_BASE_URL;
      return config
    case "staging":
      config.ECommerce.BASE_URL = config.ECommerce.BASE_URL || STAGING_BASE_URL;
      return config
    case "production":
      config.ECommerce.BASE_URL = config.ECommerce.BASE_URL || PRODUCTION_BASE_URL;
      return config
    default:
      throw new Error(
        "Could not determine environment. Please define environment variable ENV."
      );
  }
};

export default getConfig();
