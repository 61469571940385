const AUTH_LOGIN_COMPLETE = "auth/AUTH_LOGIN_COMPLETE";
const AUTH_LOGIN_REQUEST = "auth/AUTH_LOGIN_REQUEST";
const AUTH_FORGET_PASSWORD_COMPLETE = "auth/AUTH_FORGET_PASSWORD_COMPLETE";
const AUTH_FORGET_PASSWORD_REQUEST = "auth/AUTH_FORGET_PASSWORD_REQUEST";
const AUTH_RESET_PASSWORD_COMPLETE = "auth/RESET_PASSWORD_COMPLETE";
const AUTH_RESET_PASSWORD_REQUEST = "auth/RESET_PASSWORD_REQUEST";
const AUTH_RESET_PASSWORD_REQUEST_EMAIL = "auth/RESET_PASSWORD_REQUEST_EMAIL";

const AUTH_RESET_PASSWORD_QUERY_REQUEST =
  "auth/AUTH_RESET_PASSWORD_QUERY_REQUEST";
const AUTH_FINGERPRINT_AUTH_COMPLETE = "auth/FINGERPRINT_AUTH_COMPLETE";
const AUTH_FINGERPRINT_AUTH_REQUEST = "auth/FINGERPRINT_AUTH_REQUEST";
const AUTH_SEND_OTP = "auth/AUTH_SEND_OTP";
const AUTH_UPDATE = "auth/UPDATE";
const AUTH_CLEAR = "auth/CLEAR";
const AUTH_SIGNUP = "auth/SIGNUP";
const OTP_IS_VERIFIED = "OTP_IS_VERIFIED";
const UPDATE_PHONE = "UPDATE_PHONE";
const FIRST_TIME_SIGNUP = "FIRST_TIME_SIGNUP";
const PASSWORD_RESET_FLAG = "PASSWORD_RESET_FLAG";
const PHONE_IS_VERIFIED = "PHONE_IS_VERIFIED";
const CURRENT_USER_DETAIL = "CURRENT_USER_DETAIL";
const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";
const CURRENT_USER_ORDER_HISTORY = "CURRENT_USER_ORDER_HISTORY";
const RESET_PASSWORD_OTP_IS_VERIFIED = "RESET_PASSWORD_OTP_IS_VERIFIED";
const AUTH_SEND_OTP_ON_FORGOT = "AUTH_SEND_OTP_ON_FORGOT";
const LOGIN_QUERY = "LOGIN_QUERY";
export {
  LOGIN_QUERY,
  AUTH_LOGIN_COMPLETE,
  AUTH_LOGIN_REQUEST,
  AUTH_FORGET_PASSWORD_COMPLETE,
  AUTH_FORGET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_COMPLETE,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_REQUEST_EMAIL,
  AUTH_FINGERPRINT_AUTH_COMPLETE,
  AUTH_FINGERPRINT_AUTH_REQUEST,
  AUTH_RESET_PASSWORD_QUERY_REQUEST,
  AUTH_UPDATE,
  AUTH_CLEAR,
  AUTH_SIGNUP,
  OTP_IS_VERIFIED,
  AUTH_SEND_OTP,
  FIRST_TIME_SIGNUP,
  PASSWORD_RESET_FLAG,
  PHONE_IS_VERIFIED,
  CURRENT_USER_DETAIL,
  UPDATE_USER_DETAIL,
  UPDATE_PHONE,
  CURRENT_USER_ORDER_HISTORY,
  RESET_PASSWORD_OTP_IS_VERIFIED,
  AUTH_SEND_OTP_ON_FORGOT,
};
